export default [{
  header: 'Pages',
  icon: 'FileIcon',
  children: [{
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  }, {
    title: 'Users',
    route: 'users',
    icon: 'UserIcon',
  }, {
    title: 'Cards',
    route: 'cards',
    icon: 'CreditCardIcon',
  }, {
    title: 'Offers',
    route: 'offers',
    icon: 'OfferIcon',
  },
  {
    title: 'special Offers',
    route: 'special-offers',
    icon: 'FileIcon',
  },
  ],
}]
